// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from "@sentry/nextjs";
import { ExtraErrorData as ExtraErrorDataIntegration } from "@sentry/integrations";

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;

Sentry.init({
  dsn:
    SENTRY_DSN ||
    "https://6ebddd876bb64c6c8012d6b1c92ae23a@o1258316.ingest.sentry.io/4505522633244672",
  integrations: [
    new ExtraErrorDataIntegration({
      // Limit of how deep the object serializer should go. Anything deeper than limit will
      // be replaced with standard Node.js REPL notation of [Object], [Array], [Function] or
      // a primitive value. Defaults to 3.
      depth: 10,
    }),
  ],
  beforeSend(event) {
    // Check if the event contains the specific URL causing the error
    event.extra = event.extra || {};
    event.extra.customData = JSON.stringify(event);

    // Ignore this error: https://poweruplabs.sentry.io/issues/4340087826/?environment=production&project=4505522633244672&query=is%3Aunresolved&referrer=issue-stream&statsPeriod=14d&stream_index=0
    if (
      event.exception?.values[0]?.stacktrace?.frames[0]?.filename?.includes(
        "googleads.g.doubleclick.net"
      )
    ) {
      // Ignore the event
      return null;
    }
    return event;
  },
  ignoreErrors: ["Non-Error promise rejection captured"],
  allowUrls: ["gridleygame.com"],
  denyUrls: [
    // browser's extensions
    /extensions\//i,
    /^chrome:\/\//i,
    /^moz-extension:\/\//i,
  ],
  sampleRate: 0.1,
});
